.viewPopup {
    width: 500px;
    position: relative;
    padding-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
}

.viewPopupClose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.viewPopupTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 40px;
    margin-left: 8px;
}
.container {
    display: flex;
    flex-direction: column;
}

.input {
    padding: 10px !important;
}

.button {
    margin: 40px 0;
    width: 170px !important;
    height: 50px;
}

.disabled {
    background: #afafaf !important;
    cursor: default;
}
