.search_field_date {
    max-width: 125px;
    min-width: 110px !important;
}

.search_field {
    max-width: 200px;
    min-width: 200px !important;
}

.PopupTitle {
    margin-bottom: 15px;
}
.PopupContent {
    max-height: calc(100dvh - 300px);
    overflow: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: rgba(99, 99, 99, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgb(99, 99, 99);
    }
}
.Buttons > div {
    margin-top: 5px;
}

.search_field_worker {
    min-height: 14px;
    height: 30px;
    width: 90%;
    margin: 0 auto;

    div::after {
        top: 10px;
    }
}

.worker {
    line-height: 18px;
}

.button {
    height: 36px !important;
    padding: 6px 16px !important;
    margin-bottom: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        height: 20px;
        transform: rotate(-90deg);
        filter: invert(1);
    }

    &:disabled {
        img {
            filter: invert(0);
        }
    }
}

.button_create_service {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s;
    padding: 10px;
    width: 100%;
    border-radius: 6px;
    margin: 15px 0 10px;
}

.search_panel {
    flex-wrap: wrap;
}

:global {
    .ant-table-thead > tr > th {
        vertical-align: middle;
        height: 10px;
    }
    .ant-table-row {
        .ant-table-cell {
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .ant-form-item {
                margin: 6px !important;
                display: flex !important;
                align-items: center !important;
            }
        }
    }
}
.wrapper {
    padding: 0 40px;
}

.hoursInput {
    height: 40px;
    background: #fff;
    width: 100%;
}

.table {
    :global {
        .ant-table-thead tr th {
            text-align: center;
            padding: 0px 0px !important;
            line-height: 14px;
        }

        .ant-table-tbody tr td {
            height: 14px;
            padding: 0px 0px !important;
            line-height: 14px;
        }

        .ant-form-item-control-input {
            min-height: 14px;
        }
    }
}

.selectedActions {
    width: 100%;
    background-color: rgb(214, 244, 255);
    display: flex;
    align-items: center;
    padding: 10px;
    height: 50px;
    margin: 0 40px;

    input {
        width: 50%;
        height: 30px !important;
        background: #fff !important;
    }
}

.buttonsContainer_align {
    margin-top: 10px;
    * {
        margin-bottom: 0 !important;
    }
}

:global {
    .ant-popover {
        .ant-popover-message {
            width: 300px;
        }
    }
}
