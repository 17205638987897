.wrapper {
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
}
.bottom {
    margin-top: 30px;
}
.copyright {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #d9d9d9;
    margin-top: 32px;
    margin-bottom: 10px;
}

.icon {
    margin-right: 12px;
}
.bottom img {
    filter: invert(100%);
    opacity: 0.2;
}
.logo {
    margin-top: 40px;
    margin-bottom: 40px;
    filter: invert(100%);
}

.container {
    max-width: 320px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.container > input {
    width: 320px;
    height: 40px;
    background: #f2f2f2;
    border-radius: 6px;
    border: none;
    padding: 15px;
    margin-bottom: 8px;
}

.container input:focus {
    outline: none;
}
