.line1 {
    width: 100%;
    height: 78px;
    position: relative;
}

.menu_icon {
    position: absolute;
    left: 40px;
    top: 42px;
    height: min-content;
    width: min-content;

    img {
        filter: invert(5);
        height: 16px;
    }

    @media (max-width: 635px) {
        top: 13px;
        //left: 10px;
    }
}

.title {
    position: absolute;
    left: 40px;
    top: 36px;
    height: 42px;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;

    &.isMobilePlatform {
        left: 80px;
    }

    @media (max-width: 635px) {
        &.isMobilePlatform {
            top: 40px;
            left: 40px !important;
        }
    }

    @media (max-width: 349px) {
        &.isMobilePlatform {
            font-size: 25px;
        }
    }
}
