.getCodeContainer {
    :global {
        .ant-form-item-control-input-content {
            display: flex;
            flex-wrap: nowrap;
        }
    }
}

.login_button {
    height: 48px !important;
}