.button {
    width: 120px;
    margin-left: 38px;
    height: 40px;
    background: #000000;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.swap {
    @media (max-width: 1260px) {
        flex-direction: column;

        & > div {
            margin-right: 0px !important;
            &:not(:last-child) {
                margin-bottom: 20px !important;
            }
        }

        @media (max-width: 525px) {
            & > div {
                flex-direction: column;
                margin: 0 !important;

                align-items: flex-start;
                gap: 0 !important;
                & > span:last-child {
                    margin-top: 10px;
                }

                & > div {
                    flex-wrap: wrap;
                    & > div:last-child span {
                        margin: 0;
                    }
                }
            }
        }
    }

    .topRow {
        @media (max-width: 496px) {
            & > div {
                width: 100%;
            }
        }
    }
}

.requestSelect {
    width: 240px;
    @media (max-width: 600px) {
        width: 200px;
    }
}
