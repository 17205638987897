.resetPasswordWrap {
    margin-top: 10px;
}

.login_button {
    width: 120px;
    height: 48px !important;
}

.register {
    margin-top: 48px;
    width: 100%;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #b3b3b3;
}

.register a {
    color: black;
    text-decoration: none;
}

.resetContainer {
    :global {
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: space-between;
        }
    }
}
