.existingCardData {
    font-size: 16px;

    :global {
        .ant-space-item {
            width: 100%;
            border-bottom: 1px solid rgba(217,217,217,0.5);
            padding-block: 5px;
        }
    }
    .cardDataRow {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }
    .cardType {
        font-weight: 600;
    }
    .cardNumCol {
        display: flex;
        gap: 5px;
    }
    .cardNum {
        font-weight: 600;
    }
}