$baseSize: 4px;

$size-1: $baseSize;
$size-1_5x: $baseSize * 1.5;
$size-2x: $baseSize * 2;
$size-2_5x: $baseSize * 2.5;
$size-3x: $baseSize * 3;
$size-3_5x: $baseSize * 3.5;
$size-4x: $baseSize * 4;
$size-5x: $baseSize * 5;
$size-6x: $baseSize * 6;
$size-8x: $baseSize * 8;
$size-9x: $baseSize * 9;
$size-10x: $baseSize * 10;
$size-11x: $baseSize * 11;
$size_12x: $baseSize * 12;
$size_13x: $baseSize * 13;
$size-16x: $baseSize * 16;