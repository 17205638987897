.wrapper {
    width: calc(100% - 260px);
    height: 100%;
    background-color: white;
}

.mediaStyle {
    @media (max-width: 1535px) {
        & > div:first-child > div {
            flex-wrap: wrap;
        }
    }
}

.rowApprove {
    display: flex;
    justify-content: space-between;

    padding: 7px 10px;

    &:not(:last-child) {
        border-bottom: 1px solid #d9d9d9;
    }
}

.colApprove,
.colApprove2,
.colApprove3 {
    line-height: 16px;
}

.colApprove {
    width: calc(50% - 10px);
    margin-right: 10px;
}
.colApprove2 {
    width: calc(30% - 10px);
    margin-right: 10px;
}
.colApprove3 {
    width: 20%;
}

.modalApprove {
    width: auto !important;
    max-width: 60vw !important;
    min-width: 650px !important;
    // height: 90vh !important;
    top: 3vh !important;
    padding: 0 !important;

    overflow: hidden;

    :global {
        .ant-modal-header {
            background: #f8c2c2;
        }

        .ant-modal-content {
            height: 100%;
            box-sizing: border-box;
        }

        .ant-modal-body {
            box-sizing: border-box;
            height: calc(100% - 55px);

            padding: 0px 12px;
        }
    }

    form {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.modal {
    @media (max-width: 1000px) {
        width: 95vw !important;
        height: 90vh !important;
        top: 3vh !important;
        padding: 0 !important;

        overflow: hidden;

        :global {
            .ant-modal-content {
                height: 100%;
                box-sizing: border-box;
            }

            .ant-modal-body {
                box-sizing: border-box;
                height: calc(100% - 55px);

                padding-right: 12px;
                padding-left: 12px;
            }
        }

        form {
            box-sizing: border-box;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

.ordersWraper {
    width: calc(100% - 80px);
    margin: 0 40px;
    box-sizing: border-box;
}

.blockWrapper {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid rgb(50, 50, 50);
    border-top: 24px solid rgb(50, 50, 50);

    padding: 5px 10px;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    .blockHeader {
        padding: 3px 0 5px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 2px solid rgb(50, 50, 50);
        .action img {
            opacity: 1;
        }
    }
    .bodyWrapper {
        .route {
            width: 100%;
            border-bottom: 2px solid rgb(50, 50, 50);
            padding: 3px 0 5px;
        }
        .addressesWrapper {
            padding: 3px 0 5px;
            display: flex;
            flex-direction: column;
            .addressText {
                margin-right: 5px;
            }
            .addresses {
                padding-left: 5px;
                border-left: 2px solid rgb(50, 50, 50);
                display: flex;
                flex-direction: column;
            }
        }
    }
    .actionsWrapper {
        button {
            // background: rgb(228, 228, 228);
            margin: 2px;
        }

        width: 100%;
        display: flex;
        flex-direction: column;

        .actionsGroup {
            width: 100%;
            display: flex;
            justify-content: space-between;

            button {
                width: 100%;
            }
        }
    }
}

.line1 {
    width: 100%;
    height: 78px;
    position: relative;
}

.title {
    position: absolute;
    left: 40px;
    top: 36px;
    width: 123px;
    height: 42px;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
}

.topFilter {
    width: 721px;
    height: 19px;
    margin-left: 39px;
    margin-top: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topFilterItem {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s;
}

.topFilterItem:hover {
    opacity: 1 !important;
    transition: 0.1s;
}

.topFilterItem div {
    margin-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inactiveItem {
    opacity: 0.4;
}

.icon1 {
    margin-right: 12px;
    width: 16px;
    height: 16px;
}

.icon2 {
    margin-right: 11px;
    width: 20px;
    height: 20px;
}

.icon3 {
    margin-right: 11px;
    width: 18px;
    height: 18px;
}

.icon4 {
    margin-right: 11px;
    width: 18px;
    height: 18px;
}

.line2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.searchBar {
    width: 100%;
    margin-left: 39px;
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchBar input {
    margin-left: 12px;
    border: none;
    width: 100%;
    font-size: 14px;
}

.searchBar input::placeholder {
    color: #b3b3b3;
    line-height: 16px;
}

.searchBar input:focus {
    outline: none;
}

.underline {
    margin-top: 15px;
    width: 100%;
    height: 1px;
    display: block;
    background-color: #d9d9d9;
    opacity: 0.5;
}

.datePicker {
    width: 240px;
    height: 40px;
    flex-shrink: 0;
    background: #f2f2f2;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
}

.datePicker:hover {
    background: #ebebeb;
    transition: 0.1s;
}

.datePicker div {
    margin-top: 1px;
}

.datePicker img {
    margin-left: 16px;
    margin-right: 20px;
    margin-bottom: 1px;
    flex-shrink: 2;
}

.datePickerDash {
    background-color: #b3b3b3;
    width: 7px;
    height: 1px;
    margin-left: 10px;
    margin-right: 10px;
}

.dropdownMenu {
    width: 220px;
    height: 40px;
    flex-shrink: 0;
    background: #f2f2f2;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 16px;
    border: none;
}

.dropdownMenu option {
    margin-left: 10px;
}

.buttonsContainer {
    height: 40px;
    width: calc(100% - 90px);
    margin-left: 40px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
}

.buttonsContainerLeft {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
}

.buttonsContainerRight {
    width: 555px;
    min-width: 510px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonsContainerItem {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s;
    padding: 10px;
    border-radius: 6px;
}

.buttonsContainerItem:hover {
    background-color: #f2f2f2;
}

.buttonsContainerIcon1 {
    margin-bottom: 1px;
    //margin-right: 7px;
    width: 19px;
    height: 19px;
}

.buttonsContainerIcon2 {
    margin-bottom: 1px;
    margin-right: 7px;
    width: 22px;
    height: 22px;
}

.buttonsContainerIcon3 {
    margin-bottom: 1px;
    margin-right: 7px;
    width: 19px;
    height: 19px;
}

.iconFilter {
    margin-right: 1px;
    svg {
        width: 12px;
        height: 12px;
    }
}

.dropownCustomAutocompleteWorker {
    // height: 20px !important;
    width: 380px;
    // border: 3px solid #62a5fc;

    // ::after {
    //     top: 10px;
    //     width: 10px;
    //     height: 7px;
    // }

    & > div:first-child {
        padding: 1px !important;
        & > div {
            margin-right: 26px !important;
            // height: 22px !important;
        }
    }
}
.Dropdown_Menu_Input {
    padding: 2px 4px;
}

.Dropdown_Menu {
    max-height: 50dvh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: rgba(99, 99, 99, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgb(99, 99, 99);
    }

    .Dropdown_Menu_Item {
        background-color: #fff !important;
        color: rgba(0, 0, 0, 0.85) !important;
        margin: 2px 0px !important;
        user-select: none !important;

        height: 30px !important;
        line-height: 30px !important;
        padding: 0 4px;

        &.antMenuItemSelected {
            background-color: #e6f7ff !important;
            color: #1890ff !important;
        }
    }
}

.Dropdown_Menu_Buttons {
    display: flex;
    min-width: 170px;
    margin-top: 5px;

    button {
        width: calc(50% - 2.5px);
        &:first-child {
            margin-right: 5px;
        }
    }
}

.ReactInputMaskTime {
    position: relative;
    outline: none;
    border-radius: 2px;
    padding: 4px;
    width: 50px;
    height: 20px !important;
    text-align: center;

    color: rgba(0, 0, 0, 0.85);
    font-size: 11px;
    line-height: 11px;

    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
}

.ReactInputMaskDate {
    position: relative;
    outline: none;
    border-radius: 2px;
    padding: 4px;
    min-width: 70px;
    width: 100%;
    text-align: center;

    min-width: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    &:focus {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        border-right-width: 1px;
        outline: 0;
    }
    &.isError {
        border: 1px solid var(--color-warning) !important;
        &:focus {
            box-shadow: 0 0 2px rgb(255, 77, 79);
            border-color: var(--color-warning) !important;
        }
    }
}

.ReactInputMask {
    position: relative;
    outline: none;
    border-radius: 2px;
    padding: 4px;
    min-width: 70px;
    width: 100%;
    text-align: center;

    min-width: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;

    &:focus {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        border-right-width: 1px;
        outline: 0;
    }
}

.dropdown_Menu {
    position: relative;
    z-index: 99999;
    border: 3px solid #62a5fc;
    background: #fff;
    padding: 8px 10px 8px 8px;

    box-shadow: #d4d4d4 5px 6px 12px 0px;

    // &::after {
    //     content: '';
    //     position: absolute;
    //     bottom: 100%;
    //     left: 50%;
    //     margin-left: -10px;
    //     border-width: 10px;
    //     border-style: solid;
    //     border-color: transparent transparent #62a5fc transparent;
    // }

    .dropdownButton {
        margin-top: 10px;
    }
}

.dropdown {
    :global {
        .ant-dropdown-arrow {
            background: #62a5fc;
            &::before {
                background: #62a5fc;
            }
        }
    }
}

.linkStyle {
    color: #62a5fc;
    text-decoration: underline;

    &:hover {
        color: #478de7;
    }
}

.tableWrapper {
    position: relative;
    min-height: 215px;
    margin-top: 12px;
    margin-left: 30px;
    width: calc(100% - 60px);
    margin-bottom: 80px;
    padding: 0;

    thead tr {
        height: 20px;
        td {
            div {
                line-height: 10px;
                font-size: 11px;
                text-align: center;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                height: 25px;
            }

            img {
                height: 15px;
            }
        }
    }

    tbody tr {
        height: 27px;
        line-height: 27px;
        padding: 0;
        // margin: 0;
        // text-align: center;
        td {
            height: 27px;
            line-height: 27px;
            padding: 0px 2px;
            // margin: 0;
            text-align: center;
            div,
            span {
                height: 21px;
                line-height: 21px;
                // padding: 0;
                margin: 1px auto;
                text-align: center;
                display: block !important;
                overflow: hidden;
            }
        }
    }
}

.table {
    width: 100%;
    border-collapse: collapse !important;
    border-spacing: 0 0;
}

.tableHeadTd {
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.tableHead {
    // border-bottom: 1px solid #d9d9d9;
    font-family: Roboto, sans serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #b3b3b3;
    letter-spacing: 0.05em;
    text-transform: lowercase;
    height: 30px;
    padding-bottom: 8px;
    white-space: wrap;
}

.tableBody {
    &_td {
        height: 40px;
        border: solid 0px #000;
        text-overflow: ellipsis;
        max-width: 120px;
        white-space: wrap;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;

        &_item {
            margin-left: 10px;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
        }

        &_img {
            height: 40px;
            display: flex;
            align-items: flex-start;
            border-left-style: solid;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            &_item {
                margin-left: 18px !important;
            }
        }

        & > div {
            margin-left: 10px;
            height: 40px;
            line-height: 40px;
        }
    }

    &_tr {
        &:hover {
            transform: scale(1, 1);
            -webkit-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            box-shadow: 0 6px 9px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }
    }
}

.tableImgWrapper {
    display: flex;
    align-items: center;
}

.tableButton {
    opacity: 0.2;
    cursor: pointer;
}

.tableButton:not(:first-child) {
    //margin-left: 8px;
}

.tableButton:hover {
    opacity: 1;
}

.tableExpandButton {
    width: 32px;
    height: 24px;
    background: #f2f2f2;
    border-radius: 12px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    margin-left: 8px;
    padding: 0 10px;
}

.tableExpandButton:hover {
    background: #d2d2d2;
}

.tablePhotoIcon {
    margin: 10px 0;
}

.dateRange,
.dateRange > div:first-child {
    border-radius: 6px;
}

.button {
    position: absolute;
    bottom: 40px;
    width: 149px;
    height: 48px;
    background: #000000;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addressPopup {
    width: 700px;
    height: 320px;
    position: relative;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

.approveButton {
    flex-shrink: 0;
    // opacity: 0;
    width: 121px;
    height: 30px;
    // background: #000000;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    cursor: pointer;
    width: fit-content;
}

.trNew {
    background-color: rgba(135, 147, 252, 0.3);
    // background-color: #8793fc;
    &:hover {
        background-color: rgba(135, 147, 252, 0.3);
    }
}

.trRequestConfirmedByWorker {
    background-color: rgba(255, 207, 36, 0.3);
    // background-color: #ffcf24;
    &:hover {
        background-color: rgba(255, 207, 36, 0.3);
    }
}

.trStartConfirmed {
    background-color: rgba(98, 195, 203, 0.3);
    // background-color: #62c3cb;
    &:hover {
        background-color: rgba(98, 195, 203, 0.3);
    }
}

.trCompleted {
    background-color: rgba(149, 231, 110, 0.3);
    // background-color: #95e76e;

    &:hover {
        background-color: rgba(149, 231, 110, 0.3);
    }
}

.trConfirmed {
    background-color: #ebffe2;

    &:hover {
        background-color: #ebffe2;
    }
}

.needApprove {
    border: 1px #f31c4b solid !important;

    &:hover {
        border: 1px #f31c4b solid !important;
    }
}

.SearchWrapper {
    height: 35px;
}

.trSuspicious {
    background-color: #fff7f7;

    &:hover {
        background-color: #fff7f7;
    }
}

.CustomAutocompleteEstimation {
    height: 20px !important;
    width: 100px;

    ::after {
        top: 7px;
        width: 10px;
        height: 7px;
    }

    & > div:first-child {
        height: 22px !important;
        padding: 1px !important;
    }
}

.CustomAutocompleteWorker {
    height: 20px !important;
    width: 380px;

    ::after {
        top: 10px;
        width: 10px;
        height: 7px;
    }

    & > div:first-child {
        height: 22px !important;
        padding: 1px !important;
    }
}

.selectedActions {
    height: 38px;
}

.buttonsContainer2 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    &.inline {
        display: flex !important;
    }
}

.tableBody_tr {
    user-select: none;
}

.approveButton {
    height: 21px !important;
    text-align: left !important;

    &.inline {
        display: flex !important;
        overflow: initial !important;
        > .displayFlex {
            display: flex !important;
        }
    }

    &.active:hover > .displayFlex {
        display: none !important;
    }

    &.active:hover .displayNone {
        display: flex !important;
    }

    .displayNone {
        display: none !important;
        height: 21px !important;
        width: 32px !important;
        line-height: 10px !important;
        align-items: center;
        font-weight: 600;
        background: #000;
    }
}
.approveButton > div {
    height: 21px !important;
    line-height: 0px !important;
    padding: 2px 5px;
    align-items: center;
    justify-content: center;
    width: 32px;
}

.requestTable:hover .approveButton {
    opacity: 1;
}

.button1 {
    position: absolute;
    bottom: 40px;
    width: 250px;
    height: 48px;
    background: #000000;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button2 {
    width: 110px;
    height: 40px;
    border: 1px solid black;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.addressPopupLine {
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: center;
}

.photoPopup:hover > div:last-child {
    display: block;
}

.photoPopup > div:last-child {
    display: none;
    height: auto !important;
    width: auto !important;
    z-index: 1000 !important;
    opacity: 1;
    border-radius: 6px;
    padding: 0 !important;
}

.tooltip {
    position: relative;
}

.tooltip .tooltipContent {
    background: red;
    visibility: hidden;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1000;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltipContent::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
}

.tooltip:hover .tooltipContent {
    visibility: visible;
    opacity: 1;
}

.tooltipWrapper {
    position: absolute;
    z-index: 100;
}

.disabled {
    opacity: 0.5 !important;
    cursor: default !important;
}

.addressSuggestions {
    position: absolute;
    width: 400px;
}

.addressSuggestions input {
    width: 100%;
    height: 40px;
    background: #f2f2f2;
    border-radius: 6px;
    padding: 15px;
    outline: none !important;
    border: none !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.addressSuggestions input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none;
}

.tableButton2 {
    opacity: 0;
    cursor: pointer;
    //margin-left: 10px;
}

.leftText {
    text-align: left !important;
    display: block !important;
}

.hours_worked {
    width: 35px !important;
    padding: 0px 9px 9px 9px !important;
}

.hours_workedWrapper {
    width: auto;
    min-width: 0;
    height: 20px;
}

.status {
    padding: 0px 6px;
    border-radius: 4px;
    display: inline-block !important;
    height: auto !important;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 10px !important;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    margin: 3px 7px !important;
}
.status-default {
    background: #cccccc;
    color: #000000;
}
.status-autotarification_attempt {
    background: #cccccc;
}
.status-new {
    background: #8793fc;
}
.status-timepoint_confirmed {
    background: #49c6fb;
}
.status-partly_confirmed {
    background: #62a5fc;
}
.status-partly_arrived {
    background: #48dcec;
}
.status-partly_photo_attached {
    background: #33e6d1;
}
.status-photo_attached {
    background: #5de3a3;
}
.status-finished {
    background: #95e76e;
}
.status-no_response,
.status-driver_ok {
    background: #ffcf24;
}
.status-driver_callback {
    background: #62c3cb;
}

.status-declined,
.status-cancelled,
.status-removed,
.status-failed,
.status-cancelled_with_payment {
    background: #ff4f4f;
}

.addressDetails {
    padding: 4px;
    border: 2px solid rgb(50, 50, 50);
    gap: 0 !important;
    margin-bottom: 5px;

    .addressDetailsHeader {
        display: flex;
        justify-content: space-between;

        .addressDetailsHeaderItem {
            border: 2px solid rgb(50, 50, 50);
            padding: 4px;
        }
    }

    .addressDetailsButtons {
        width: 100%;
        display: inline-grid;
        grid-template-columns: 2fr 2fr 1fr;
        grid-gap: 5px;

        .doubleColumnBtn {
            grid-column: span 2;
        }
        button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .hoursMargined {
        margin-right: 6px;
    }

    .photoGallery {
        margin-top: 10px;
        //min-height: 50px;
        //.spinner {
        //    height: 50px;
        //    padding-block: 25px;
        //}
    }

    .photoLoaderContainer > div {
        min-height: 50px;
    }

    .photoLoaderContainer {
        :global {
            .ant-spin-container {
                min-height: 0 !important;
            }
        }
    }
}
