.description {
    max-height: 80px;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;

    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: rgba(99, 99, 99, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgb(99, 99, 99);
    }
}

.worker {
    line-height: 18px;
}

.service_name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
    overflow-wrap: break-word;
    text-align: left;
    font-size: 14px;
}

.hours_worked {
    width: 45px !important;
}

.hours_workedWrapper {
    width: auto;
    min-width: 0;
    height: 20px;
    display: flex;
    align-items: center;
}

.status {
    padding: 0px 6px;
    border-radius: 4px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: auto !important;
    min-height: 24px;
    min-width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 12px !important;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0 auto;
}

.status-default {
    background: #cccccc;
    color: #fff;
    font-size: 9px;
    line-height: 11px !important;
    letter-spacing: 0.07em;
}
.status-autotarification_attempt {
    background: #cccccc;
}
.status-new {
    background: #8793fc;
}
.status-timepoint_confirmed {
    background: #49c6fb;
}
.status-partly_confirmed {
    background: #62a5fc;
}
.status-partly_arrived {
    background: #48dcec;
}
.status-partly_photo_attached {
    background: #33e6d1;
}
.status-photo_attached {
    background: #5de3a3;
}
.status-finished {
    background: #95e76e;
}
.status-no_response,
.status-driver_callback {
    background: #ffcf24;
}
.status-declined,
.status-cancelled,
.status-removed,
.status-failed,
.status-cancelled_with_payment {
    background: #ff4f4f;
}

.root {
    padding: 0 40px;

    overflow-x: auto;
    position: relative;
    min-height: calc(100vh - 250px);
    user-select: none;

    :global {
        .ant-table-thead tr th {
            text-align: center;
            padding: 0px 0px !important;
            line-height: 14px;
        }

        .ant-table-tbody tr td {
            height: 14px;
            padding: 0px 2px !important;
            text-align: center;
        }
        .ant-table-tbody tr td:first-child {
            padding: 0px 0px 0px 9px !important;
        }

        .ant-dropdown-trigger {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    .search_field {
        min-height: 14px;
        height: 30px;

        div::after {
            top: 10px;
        }
    }
}

.wideCell {
    min-width: 200px;
}
