.singup_button {
    width: 200px;
    height: 48px !important;
}

.checkbox_link {
    color: var(--color-primary);
}

.legalCheckBox {
    padding: 5px 0 0 0 !important;
    margin-bottom: 0 !important;

    :global {
        .ant-form-item-label {
            padding-top: 0 !important;
        }
    }
}

.codeOption {
    padding: 5px 0 0 0 !important;
    margin-bottom: 10px !important;
    align-items: center;

    :global {
        .ant-form-item-label > label::after {
            content: ' ';
        }

        .ant-form-item-label {
            padding-top: 0 !important;
        }

        .ant-radio-button-wrapper-checked {
            background: rgba(0, 0, 0, 0.85) !important;
            border-color: rgba(0, 0, 0, 0.85) !important;
        }

        .ant-radio-button-wrapper-checked::before {
            background: rgba(0, 0, 0, 0.85) !important;
        }

        .ant-radio-button-wrapper-checked::after {
            background: rgba(0, 0, 0, 0.85) !important;
        }

        .ant-radio-button-wrapper:hover {
            color: rgba(0, 0, 0, 0.8) !important;
        }

        .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
            color: rgb(255, 255, 255) !important;
        }

        .ant-radio-button-wrapper {
            box-shadow: none !important;
        }
    }
}

.formItemLabel {
    margin-bottom: 15px !important;
}
