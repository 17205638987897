

.wrapper {
    width: -webkit-fill-available;
    width: -moz-available;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
}

.logo {
    margin-top: 40px;
    margin-bottom: 40px;
    filter: invert(100%);
}

.container {
    max-width: 320px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.container > input {
    width: 320px;
    height: 40px;
    background: #F2F2F2;
    border-radius: 6px;
    border: none;
    padding: 15px;
    margin-bottom: 8px;
}

.container input:focus {
    outline: none;
}
.lastInput {
    margin-bottom: 32px !important;
}
.title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0px;
}
.info {
    font-size: 18px;
    line-height: 1.5;
}
.info p {
    margin-bottom: 22px;
}
.label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #B3B3B3;
    margin: 8px 8px 8px 0;
}

.line {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}

.resetPasswordWrap {
    margin-top: 10px;
}

.resetPasswordLink {
    color: black;
    text-decoration: none;
}

.button {
    width: 170px;
    height: 48px;
    background: #000000;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.buttonSign {
    width: 200px;
    height: 48px;
    background: #000000;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 32px;
}

.disabled {
    opacity: 0.5;
    cursor: default;
}
.checkboxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 16px;
}

.checkboxLabel {
    margin-top: 4px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    /* Grey */

    color: #B3B3B3;
}
.checkboxLabel a {
    text-decoration: none;
    margin-left: 5px;
    margin-top: 4px;
    color: black;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}
/* Hide the browser's default checkbox */
.checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #f2f2f2;
    border-radius: 6px;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add background */
.checkboxContainer input:checked ~ .checkmark {
    background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.register {
    margin-top: 48px;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #B3B3B3;
}

.register a {
    color: black;
    text-decoration: none;
}

.bottom {
    margin-top: 30px;
}

.bottom img {
    filter: invert(100%);
    opacity: .2;
}

.icon {
    margin-right: 12px;
}

.copyright {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #D9D9D9;
    margin-top: 32px;
    margin-bottom: 10px;
}

