.mediaStyle {
    @media (max-width: 1535px) {
        & > div:first-child > div {
            flex-wrap: wrap;
        }
    }
}

.noIndent {
    margin: 0;
    padding: 0;
}