
.line2 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
}

.searchBar {
        width: 100%;
        margin-left: 39px;
        margin-top: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
}

.searchBar input {
        margin-left: 12px;
        border: none;
        width: 100%;
        font-size: 14px;
}

.searchBar input::placeholder {
        color: #b3b3b3;
        line-height: 16px;
}

.searchBar input:focus {
        outline: none;
}

.underline {
        margin-top: 15px;
        width: 100%;
        height: 1px;
        display: block;
        background-color: #d9d9d9;
        opacity: 0.5;
}
.itemsCountWrapper {
        position: relative;
        margin: -13px;
        padding: 13px;
        border-radius: 6px;
        margin-right: 40px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        cursor: pointer;
}
.itemsCount {
        display: flex;
        min-width: max-content;

        background: #FFFFFF;
}
.itemsCountWrapper:hover {
        box-shadow: 0 8px 8px rgba(0,0,0,0.1);
        border-radius: 6px 6px 0 0;
}

.itemsCount span {
        color: #B3B3B3;
        margin-right: 10px;
}

.itemsCount img {
        margin-left: 8px;
}

.itemsCountDropdown {
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        display: none;
        width: 100%;
        box-shadow: 0 8px 8px rgba(0,0,0,0.1);
        background: #FFFFFF;
        z-index: 99;
        text-align: right;
}
.itemsCountWrapper:hover .itemsCountDropdown, .itemsCountDropdown:hover {
        display: flex;
        flex-direction: column;
}
.itemsCountSelect {
        padding: 13px 30px;
}
.itemsCountSelect:hover {
        background: #EEEEEE;
}