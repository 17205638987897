.scrollWrapper {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 0 40px 0 300px;
}
.scrollContent {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding: 0 16px;
}

.scroll {
    width: 100%!important;
}
.forSlider {
    flex-grow: 1;
    padding: 0 50px;
    background-color: #F2F2F2;
    height: 4px;
    border-radius: 2px;
}