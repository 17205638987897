.tableWrapper {
        position: relative;
        min-height: 215px;
        margin-top: 44px;
        margin-left: 30px;
        width: calc(100% - 60px);
        overflow: hidden;
        padding: 20px 10px 0;
}

.table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 0;
}

.table > tr > td {
}

.table > tr > td > div {
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
}

.table > tr > td > div > a {
        color: inherit;
        text-decoration: none;
}
.table > tr > td > div > div {
        cursor: pointer;
}
.table > tr > td > div > div > span {
        font-size: 13px;
        line-height: 15px;
        color: #B3B3B3;
}


.table > tr:first-child > td {
        border-bottom: 1px solid #d9d9d9;
        font-family: Roboto, sans serif;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        color: #B3B3B3;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        height: 30px;
        padding-bottom: 8px;
        white-space: nowrap;
}

.table > tr:not(:first-child):not(:nth-child(2)) > td {
        height: 40px;
        border: solid 0px #000;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
}



.table > tr:not(:first-child):not(:nth-child(2)) > td:first-child > img {
        margin-left: 18px !important;
}

.table > tr:not(:first-child):not(:nth-child(2)) > td:first-child {
        border-left-style: solid;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
}

.table > tr:not(:first-child):not(:nth-child(2)) > td:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
}

.table > tr:not(:first-child):not(:nth-child(2)):hover {
        transform: scale(1,1);
        -webkit-transform: scale(1,1);
        -moz-transform: scale(1,1);
        box-shadow: 0 8px 8px rgba(0,0,0,0.1);
        border-radius: 4px;
}

.table > tr:nth-child(2) > td {
        height: 8px;
}


