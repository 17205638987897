.title {
    font-family: Roboto, sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 24px !important;
    line-height: 28px !important;
    margin-bottom: 0px;
}
.info {
    font-size: 14px;
}
.info p {
    margin-bottom: 22px;
}
.label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #b3b3b3;
    margin: 8px 8px 8px 0;
}
.line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.resetPasswordLink {
    color: black;
    text-decoration: none;
}
