.loading {
    width: 96px;
    height: 28px;
    background: #f2f2f2;
    border-radius: 6px;
    padding: 7px;
    font-family: Roboto, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading div {
    position: relative;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #b3b3b3;
    animation: move 1s ease infinite;
}

.loading div:first-child {
    animation-delay: 0.2s;
}

.loading div:nth-child(2) {
    margin: 3px;
    animation-delay: 0.4s;
}

.loading div:last-child {
    animation-delay: 0.6s;
}
@keyframes move {
    from {
        bottom: 0;
    }
    to {
        bottom: 0;
    }
    50% {
        bottom: 3px;
    }
    60% {
        bottom: 0;
    }
}
.success {
    width: 96px;
    height: 28px;
    background: #f2f2f2;
    border-radius: 6px;
    padding: 7px;
    font-family: Roboto, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    height: 28px;
    background: #ffd3d3;
    border-radius: 6px;
    padding: 7px;
    font-family: Roboto, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}