.smallTitle {
    margin-left: 40px;
    margin-top: 72px;
    margin-bottom: 35px;
    font-family: Roboto, sans-serif;
    line-height: 21px;
}

.contacts {
    margin-right: 40px;
    width: 790px;

    @media (max-width: 1000px) {
        width: 100vw;

        & > div > h4,
        & > div > div {
            margin: 20px 0 20px 40px !important;
        }
    }
}

.line4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.line4 > .buttonsContainerItem {
    margin-top: 72px;
    margin-bottom: 35px;
}

.buttonsContainerItem {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s;
    padding: 10px;
    border-radius: 6px;
}

.buttonsContainerItem:first-child {
    margin-right: 16px;
}

.buttonsContainerItem:hover {
    background-color: #f2f2f2;
}

.table {
    width: calc(100% - 40px);
    margin-left: 40px;
    border-collapse: separate;
    border-spacing: 0 0;

    @media (max-width: 1000px) {
        &:last-child {
            margin-top: 20px;
        }
        & > tbody > tr > td:not(:first-child) {
            width: 50%;
        }
    }
}

.table > tbody > tr > td > div {
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table > tbody > tr:first-child > td {
    border-bottom: 1px solid #d9d9d9;
    font-family: Roboto, sans serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: #b3b3b3;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    height: 30px;
    padding-bottom: 8px;
    white-space: nowrap;
}

.table > tbody > tr:not(:first-child):not(:nth-child(2)) > td {
    height: 40px;
    border: solid 0 #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}

.table > tbody > tr:not(:first-child):not(:nth-child(2)) > td:first-child {
    border-left-style: solid;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.table > tbody > tr:not(:first-child):not(:nth-child(2)) > td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.table > tbody > tr:not(:first-child):not(:nth-child(2)):hover {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.table > tbody > tr:nth-child(2) > td {
    height: 8px;
}

.buttonsContainerIcon1 {
    margin-bottom: 1px;
    margin-right: 7px;
    width: 19px;
    height: 19px;
}

.viewPopup {
    width: 440px;
    height: 540px;
    position: relative;
    padding-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
}

.viewPopupClose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.viewPopupTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 40px;
    margin-left: 8px;
}

.popupButton {
    position: absolute;
    bottom: 40px;
    width: 240px;
    height: 48px;
    background: #000000;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 8px;
}

.inputWrapper > input,
.inputWrapper > textarea {
    width: 100%;
    height: 40px;
    background: #f2f2f2;
    border-radius: 6px;
    border: none;
    padding: 15px;
    margin-bottom: 16px;
}

.inputWrapper > input:focus,
.inputWrapper > textarea:focus {
    outline: none;
}

.label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #b3b3b3;
    margin-bottom: 8px;
}

.container1 {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.inputWrapper {
    flex: 1 100%;
    margin: 0 8px;
}
