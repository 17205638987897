.spinner {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        text-align: center;
        width: 100%;
        padding: 20px;
        background: #cccccc61;
}

@keyframes ldio2ackyrwzevd-o {
        0%    { opacity: 1; transform: translate(0 0) }
        49.99% { opacity: 1; transform: translate(40px,0) }
        50%    { opacity: 0; transform: translate(40px,0) }
        100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio2ackyrwzevd {
        0% { transform: translate(0,0) }
        50% { transform: translate(40px,0) }
        100% { transform: translate(0,0) }
}
.ldio2ackyrwzevd div {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        top: 30px;
        left: 10px;
}
.ldio2ackyrwzevd div:nth-child(1) {
        background: #e90c59;
        animation: ldio2ackyrwzevd 1s linear infinite;
        animation-delay: -0.5s;
}
.ldio2ackyrwzevd div:nth-child(2) {
        background: #46dff0;
        animation: ldio2ackyrwzevd 1s linear infinite;
        animation-delay: 0s;
}
.ldio2ackyrwzevd div:nth-child(3) {
        background: #e90c59;
        animation: ldio2ackyrwzevd-o 1s linear infinite;
        animation-delay: -0.5s;
}
.loadingiospinner {
        width: 69px;
        height: 69px;
        display: inline-block;
        overflow: hidden;
        background: none;
        margin: 0 auto;
}
.ldio2ackyrwzevd {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(0.69);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
}
.ldio2ackyrwzevd div { box-sizing: content-box; }
