.wrapper {
    width: 100%;
    height: 100%;
    background-color: white;
}

.line1 {
    width: 100%;
    height: 78px;
    position: relative;
}

.title {
    position: absolute;
    left: 40px;
    top: 36px;
    height: 42px;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
}

.balance {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #fd1b1b;
}

.warning {
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-top: 32px;
    color: #fd1b1b;
}

.warning svg {
    position: relative;
    bottom: 1px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    flex-shrink: 0;
}

.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
}

.hidden {
    display: none;
}

.smallTitle {
    font-family: Roboto, sans-serif !important;
    font-style: normal;
    line-height: 21px;
}

.dropdownMenu {
    width: 280px;
    height: 40px;
    flex-shrink: 0;
    background: #f2f2f2;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border: none;
    margin-bottom: 16px;
}

.dropdownMenu option {
    margin-left: 10px;
}

.icon {
    margin-right: 5px;
}

.addressSuggestions {
    position: relative;
}

.addressSuggestions input {
    width: 100%;
    height: 28px;
    background: #f2f2f2;
    border-radius: 6px;
    padding: 15px;
    outline: none !important;
    border: none !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.addressSuggestions input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none;
}

.form {
    position: relative;
    margin: 40px 0 0 40px !important;
    width: 40% !important;

    @media (max-width: 1000px) {
        width: calc(100% - 80px) !important;
    }

    &_container {
        width: 100% !important;
    }

    &_blocker {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 105%;

            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            top: -10px;
            left: -2.5%;
        }
    }
}

.notLegalContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form.notLegal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-inline: 0 !important;
    width: 100% !important;
    margin-top: 0 !important;

    .addCardButton {
        margin-top: 10px;
    }

    .buttonsContainerItem {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.1s;
        padding: 10px;
        border-radius: 6px;
    }

    .buttonsContainerItem:first-child {
        margin-right: 16px;
    }

    .buttonsContainerItem:hover {
        background-color: #f2f2f2;
    }

    .cardData {
        transition: height 1s;
        padding-block: 10px;
        margin-bottom: 10px;

        :global {
            .ant-row {
                gap: 10px;
                flex-wrap: nowrap;
                margin-bottom: 0 !important;

                .ant-form-item-label {
                    flex-basis: auto !important;
                }
            }

            label {
                font-weight: 500;
            }
        }

        .cardIcon {
            width: 20px;
            height: 20px;
        }

        .cardDataSubmit {
            width: 100%;
            display: flex;
            //flex-direction: row-reverse;
            @media (max-width: 601px) {
                margin-top: 10px;
            }
        }

        .cardInputContainer {
            :global {
                .ant-form-item-control-input-content {
                    padding: 4px 11px;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    border: 1px solid #d9d9d9;
                    gap: 11px;
                }

                .ant-form-item-control-input-content:focus {
                    border-color: #40a9ff;
                    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
                    border-right-width: 1px;
                    outline: 0;
                }

                .ant-form-item-control-input-content:hover {
                    border-color: #40a9ff;
                    border-right-width: 1px;
                    outline: 0;
                }

                input {
                    padding: 0;
                    border: none !important;
                    background: transparent;
                }

                .ant-input:focus {
                    border-color: #d9d9d9 !important;
                    box-shadow: none !important;
                }
            }
        }
    }

    @media (min-width: 601px) {
        margin-left: 40px !important;
        width: 40% !important;
        .ant-col-8 {
            max-width: unset !important;
        }
    }
}

.form.notLegal > * {
    padding-inline: 40px !important;
    @media (min-width: 601px) {
        padding-inline: 0 !important;
    }
}

.form.notLegal:last-child {
    margin-bottom: 20px !important;
}

.form_container {
    @media (max-width: 600px) {
        :global {
            .ant-form-item-label, .ant-form-item-control {
                flex: 1 1 !important;
            }

            .ant-form-item {
                margin-bottom: 0 !important;
            }
        }
    }
}

.cardInput {
    color: #9a9a9a;
}

.cardHolder {
    text-transform: uppercase;
}

.cardSecondary {
    display: grid !important;
    grid-template-columns: 2fr 1fr;
}

.noGap {
    gap: 0 !important;
}

