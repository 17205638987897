.viewPopup {
    width: 500px;
    position: relative;
    padding: 40px 32px 0 32px;
}

.viewPopupClose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.controls {
    margin-top: auto !important;
    margin-bottom: 0 !important;
    :global {
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: space-between;
        }
    }
}

.buttonsContainerItem {
    margin-top: 12px;
    margin-bottom: 35px;

    .buttonsContainerIcon1 {
        margin-right: 5px;
    }
}

.buttonsContainerIconDelete {
    height: 100%;
    margin-top: 17px;
    display: flex;
    align-items: center;
}

.cen {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.buttonsContainerItem {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s;
    padding: 10px;
    border-radius: 6px;
}

.buttonsContainerItem:first-child {
    margin-right: 16px;
}

.buttonsContainerItem:hover {
    background-color: #f2f2f2;
}

.viewPopupTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 40px;
    margin-left: 8px;
}

.form_input,
.form_textarea,
.form_checkbox input {
    height: 40px;

    &:focus {
        outline: none;
    }
}

.form_textarea {
    max-height: 222px;
    max-width: 100%;
}

.label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #b3b3b3;

    &.noWrap {
        white-space: nowrap;
    }
}

.time > div {
    justify-content: flex-start;
}

.labelModificate {
    padding: 5px 0 0 0;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    font-size: 14px;

    &:before {
        display: inline-block;
        margin-right: 4px;
        color: rgb(255, 77, 79);
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}

.container {
    display: flex;
    flex-direction: column;
}

.datePicker {
    cursor: pointer;
    caret-color: transparent;
    height: 40px !important;
    width: 100% !important;
    visibility: visible !important;
    background: var(--color-gray) !important;
    color: var(--color-black) !important;
}

.datePicker:hover {
    background: #ebebeb !important;
    transition: 0.1s;
}

.disabled:hover {
    background: #afafaf !important;
}

.addressSuggestions {
    position: relative;
}

.addressSuggestions input {
    width: 100%;
    height: 40px;
    background: #f2f2f2;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 16px;
    outline: none !important;
    border: none !important;
}

.addressSuggestions input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none;
}

.disabled {
    opacity: 0.5 !important;
    background: #afafaf !important;
    cursor: default !important;
}

.textarea {
    margin-top: 10px !important;

    &_tooltip {
        font-size: 10px;
        white-space: pre-wrap;
        color: black;
    }
}

.collapse {
    margin-top: 20px !important;

    :global {
        .ant-collapse-header {
            color: rgba(0, 0, 0, 0.85) !important;
            font-size: 14px !important;
            line-height: 1.5715 !important;
            font-weight: 500;
        }
    }
}

.withBgDisabled {
    transition: all 0.1s !important;
    &:disabled {
        background: #afafaf !important;
        cursor: default !important;
    }
}

.or {
    height: 100%;
    width: 32px;

    padding-top: 35px;

    display: flex;
    align-items: center;
}

.form {
    &_checkbox {
        & :global {
            & .ant-radio-inner {
                width: 35px;
                height: 35px;

                &::after {
                    width: 10px;
                    height: 12px;
                }
            }

            & .ant-checkbox-inner {
                width: 35px;
                height: 35px;

                &::after {
                    width: 9px;
                    height: 18px;
                }
            }

            & .ant-radio,
            & .ant-checkbox {
                font-size: 20px;
            }
        }
    }
    &_input {
        margin-bottom: 0 !important;
    }
}

.formBody {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: rgba(99, 99, 99, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgb(99, 99, 99);
    }
}
.button {
    margin-top: 20px;
    width: 240px !important;
    height: 48px !important;
    @media (max-width: 1000px) {
        width: 160px !important;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.uploadPhotoContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.formInput {
    background: var(--color-gray) !important;
    border: none !important;
    border-radius: 5px !important;
}
