.wrapper {
    position: relative;

    // todo так получше
    // position: fixed;
    // z-index: 1;
    // height: 100vh;
    // min-height: 850px;
    &.mobile {
        position: fixed;
        min-height: 100vh;
        z-index: 101;
        top: 0;
    }

    flex-shrink: 0;
    width: 260px;
    min-height: 850px;
    background-color: black;
    color: white;

    @media (min-width: 1001px) {
        &.isClosed,
        .logo,
        .text,
        .paymentInfo,
        .socials,
        .price {
            transition: all 0.3s ease-in-out;
        }
        &.isClosed:not(:hover) {
            width: 115px;
            min-height: 930px;

            .logo {
                margin: 20px;
            }

            .menu {
                // margin-top: 93px;
            }

            .text {
                width: 0px;
            }

            .childrensWrapper {
                width: 0px;
                max-height: 0px;
                transition: max-height 0.2s ease-in-out;
            }

            .paymentInfo {
                padding-right: 0px;
                margin-left: 5px;

                font-size: 11px;
                line-height: 15px;
                letter-spacing: 0.1px;
            }

            .price {
                font-size: 14px;
            }

            .socials {
                height: 74px;
                margin-left: 15px;
                margin-block: 10px;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }

            .copyright {
                left: 15px;
                bottom: 35px;
            }
        }
    }

    .childrensWrapper {
        width: 100%;
        height: auto;
        max-height: 1000px;
        overflow: hidden;
        transition: max-height 0.3s 0.3s ease-in-out;
    }

    @media (max-width: 1000px) {
        width: 100vw;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.menu_icon {
    position: absolute;
    z-index: 999;
    left: 10px;
    top: 10px;

    img {
        filter: invert(5);
    }
}

.close {
    position: absolute;
    right: 30px;
    top: 20px;
    img {
        filter: contrast(5);
    }
}

.logo {
    height: 40px;
    margin-left: 40px;
    margin-top: 40px;
    @media (max-width: 1000px) {
        height: 8vh;
        margin-left: 0px;
    }
}

.menu {
    margin-top: 93px;
    top: 174px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1000px) {
        width: calc(100vw - 50px);
        height: calc(70vh - 50px);
    }

    @media (max-width: 1000px) and (max-height: 630px) {
        flex-flow: row;
        flex-wrap: wrap;

        margin-top: 40px;
        height: min-content;
    }
}

.menuItem {
    position: relative;
    width: 100%;
    padding: 11px 40px 11px 40px;
    display: flex;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    @media (max-width: 1000px) {
        font-size: 2em;
        line-height: 2em;
        padding: 0.5em 1em 0.5em 1em;

        img {
            height: 0.9em;
            margin-right: 10px;
        }
    }

    @media (max-width: 1000px) and (max-height: 630px) {
        width: 33%;

        font-size: 1.4em;
        line-height: 1.4em;

        flex-direction: column;
        justify-content: center;

        img {
            height: 1.4em;
            margin-right: 0px;
        }

        .text {
            margin-left: 0;
            margin-top: 5px;
        }
    }
}

.childMenuItem {
    padding-left: 50px;
    @media (max-width: 1000px) {
        padding-left: 1.1em;
    }
}

.text {
    width: 100%;
    position: relative;
    margin-left: 11px;
    display: flex;
    overflow: hidden;
}
.text img {
    margin-left: 8px;
}
.active {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%);

    @media (max-width: 1000px) and (max-height: 630px) {
        background: rgba(243, 243, 243, 0.3);
    }
}

.paymentInfo {
    padding-right: 20px;
    margin-left: 40px;
    margin-top: 20px;
    bottom: 174px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-wrap: normal;
}

.price {
    margin-top: 3px;
    font-size: 17px;
    font-weight: bold;
}

.socials {
    height: 32px;
    width: 74px;
    margin-left: 39px;
    margin-block: 20px;
    bottom: 111px;

    display: flex;
    justify-content: space-between;
}
.socials img {
    display: block;
    width: 32px;
}
.socials img:last-child {
    // margin-left: 10px;
}

.fullscreen {
    position: fixed;
    bottom: 20px;
    right: 20px;

    z-index: 999;

    padding: 10px 15px;

    border-radius: 15px;
    background: #2f3031;
    color: #fff;
    font-weight: 400;
}

.copyright {
    position: absolute;
    width: 87px;
    height: 14px;
    left: 40px;
    bottom: 55px;
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 14px;
    opacity: 0.5;

    @media (max-width: 1000px) {
        left: 50%;
        transform: translateX(-50%);

        font-size: 2em;
        line-height: 2em;
        padding: 0.5em 1em 0.5em 1em;
        bottom: calc(1em + 64px);
        width: max-content;
    }

    @media (max-width: 1000px) and (max-height: 630px) {
        bottom: calc(1em + 20px);
        font-size: 1.3em;
        line-height: 1.3em;
    }
}

.menu a {
    color: white !important;
    text-decoration: none;
}

.disabled {
    opacity: 0.5;
}

.reportsCount {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    background: #ffffff;
    color: #000;
    margin-left: 12px;
}
