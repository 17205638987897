.requisitesForm {
    .requisites {
        gap: 10px;
        font-family: Roboto, sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: normal;
        padding-block: 30px;
        height: 100vh;
        display: grid;
        grid-template-rows: 4fr 3fr 2fr 1fr;

        .cardData {
            :global {
                .ant-row {
                    gap: 10px;
                    flex-wrap: nowrap;
                    margin-bottom: 0 !important;

                    .ant-form-item-label {
                        //flex-grow: 0 !important;
                        //flex-shrink: 1 !important;
                        flex-basis: auto !important;
                    }
                }

                label {
                    font-weight: 500;
                }
            }

            .cardIcon {
                width: 20px;
                height: 20px;
            }

            .cardDataSubmit {
                display: none;
            }

            .cardInputContainer {
                :global {
                    .ant-form-item-control-input-content {
                        padding: 4px 11px;
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;
                        border: 1px solid #d9d9d9;
                        gap: 11px;
                    }

                    .ant-form-item-control-input-content:focus, .ant-form-item-control-input-content:hover {
                        border-color: #40a9ff;
                        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
                        border-right-width: 1px;
                        outline: 0;
                    }

                    input {
                        padding: 0;
                        border: none !important;
                    }

                    .ant-input:focus {
                        border-color: #d9d9d9 !important;
                        box-shadow: none !important;
                    }
                }
            }
        }

        .requisitesTitle {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .requisitesTitleSecondary {
            font-size: 14px;
            font-weight: 250;
            margin-bottom: 20px;
            font-style: italic;
            color: #fd1b1b;
        }

        .cardInput {
            color: #9a9a9a;
        }

        .cardHolder {
            text-transform: uppercase;
        }

        .cardSecondary {
            display: grid !important;
            grid-template-columns: 2fr 1fr;
        }

        .infoData {
            //border-radius: 7px;
            background: #f1f1f1;
            width: 100%;
            padding-block: 20px;
            padding-inline: 10px;

            .cardInfo {
                // numbered list

                padding-inline: 30px;
                //display: flex;
                //flex-direction: column;
                //align-items: start;
            }

            .cardInfo.listTitle {
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 8px;
            }
        }

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: end;

            .backButton {
                background: #1083ff;
                color: white;
            }

            .nextButton {
                background: #2b8f00;
                color: white;
            }
        }
    }

    .requisites > * {
        padding-inline: 20px;
    }

    :global {
        .ant-form-item-has-error {
            .ant-form-item-control {
                border: 1px solid #ff4d4f !important;
                border-radius: 5px;
            }

            .ant-input {
                border: none !important;
            }

            .addressSuggestions input {
                border: 1px solid #ff4d4f !important;
            }
        }

        .ant-form-item-explain {
            display: none !important;
        }

        .addressSuggestions .ant-input-status-error,
        .form_input .ant-input-status-error,
        .form_textarea .ant-input-status-error,
        .form_checkbox .ant-input-status-error {
            background: #f2f2f2 !important;
            border: 1px solid #ff4d4f !important;
        }
    }
}

.labelOr {
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.dateRow {
    gap: 4px;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap !important;
}

.addressSuggestions {
    position: relative;
}

.addressSuggestions input {
    width: 100%;
    height: 40px;
    background: #f2f2f2 !important;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 0 !important;
    outline: none !important;
    border: none;
}

.addressSuggestions input:focus {
    outline: none !important;
    //border: none !important;
    box-shadow: none;
}

.form_input,
.form_textarea,
.form_checkbox input {
    width: 100%;
    height: 40px;
    background: #f2f2f2 !important;
    border-radius: 6px;
    padding: 15px;
    outline: none !important;
    border: none;

    &:focus {
        outline: none;
    }
}

.buttonsContainerIconDelete {
    height: 100%;
    padding-top: 18px;
    display: flex;
    align-items: center;
}

.buttonsContainerItem {
    margin-top: 8px;
    //margin-bottom: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.uploadPhotoContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .cameraIcon {
        margin-right: 3px;
    }
}

.loader {
    min-width: 100%;
    height: 100vh;
    padding-top: 100px !important;
}

.rowItem {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.gabaritData {
    display: grid !important;
    gap: 5px;
    grid-template-columns: 1fr 1fr;

    :global {
        .ant-row {
            margin: 0 !important;
        }
    }
}

.importInput {
    width: 100%;

    textarea {
        min-height: 150px !important;
    }

    .label {
        color: black !important;
    }
}

.amountDescription {
    width: 100%;

    :global {
        .ant-row {
            padding-inline: 10px;
            padding-bottom: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            border-bottom: 1px solid rgba(217, 217, 217, 0.5);

            label {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 13px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: rgb(179, 179, 179);
            }
        }
    }
}

.services {
    :global {
        .ant-row {
            display: flex;
            flex-wrap: nowrap !important;
            gap: 5px !important;
        }
    }
}

.formInput {
    background: var(--color-gray) !important;
    border: none !important;
    border-radius: 5px !important;
}
