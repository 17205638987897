.item {
    &__pointer {
        cursor: pointer;
    }
}

.wrapper {
    min-width: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input[type='number'] {
    -moz-appearance: textfield;
}
