.tableWrapper {
    position: relative;
    min-height: 215px;
    margin-top: 24px;
    margin-left: 30px;
    width: calc(100% - 60px);
    height: 80vh;

    margin-bottom: 80px;

    -ms-overflow-style: none;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    overflow-x: auto;
}

.tableWrapper::-webkit-scrollbar {
    width: 0;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0;
}

.tableHead {
    border-bottom: 1px solid #d9d9d9;
    font-family: Roboto, sans serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 20px;
    color: #b3b3b3;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    height: 30px;
    padding-bottom: 8px;
    white-space: nowrap;
}

// centering the text in the table head
.tableHeadTd {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.tableHead {
    &_sticky {
        top: 0;
        position: sticky;
        z-index: 4;
        background: white;
    }
}

.staticCell {
    top: 0;
    left: 0;
    position: sticky;
    z-index: 5;
    background: white;
}

.tableBody {
    &_td {
        min-height: 40px;
        border-bottom: 1px solid #f0f0f0;
        text-overflow: ellipsis;
        //max-width: 120px;
        white-space: nowrap;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;

        &_sticky {
            left: 0;
            position: sticky;
            z-index: 3;
            background: white;
        }

        &_item {
            margin-left: 10px;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
        }

        &_img {
            //height: 40px;
            display: flex;
            align-items: flex-start;
            border-left-style: solid;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            &_item {
                margin-left: 18px !important;
            }
        }

        & > div {
            //margin-left: 10px;
            //height: 40px;
            line-height: 40px;
        }
    }

    &_tr {
        &:hover {
            transform: scale(1, 1);
            -webkit-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            box-shadow: 0 6px 9px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }
    }
}

.tableBodyDiv {
    display: flex;
    flex-direction: column;
    //min-width: 85px;
}

.tableBodyLocation {
    font-family: Roboto, sans serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 20px;
    color: #b3b3b3;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    height: 30px;
    padding-bottom: 8px;
    white-space: nowrap;
}

.noIndent {
    margin: 0;
    padding: 0;
}

.RequestCell {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    border-radius: 10px;
    margin-block: 5px;
}

.buttonContainerIcon {
    padding: 3px;
}

.RequestCell > div {
    //height: 40px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    color: white;
}

.status-default {
    background: #cccccc;
    color: #000000;
}

.status-autotarification_attempt {
    background: #cccccc;
}

.status-new {
    background: #8793fc;
}

.status-timepoint_confirmed {
    background: #49c6fb;
}

.status-partly_confirmed {
    background: #62a5fc;
}

.status-partly_arrived {
    background: #48dcec;
}

.status-partly_photo_attached {
    background: #33e6d1;
}

.status-photo_attached {
    background: #5de3a3;
}

.status-finished {
    background: #95e76e;
}

.status-no_response,
.status-driver_callback {
    background: #ffcf24;
}

.status-declined,
.status-cancelled,
.status-removed,
.status-failed,
.status-cancelled_with_payment {
    background: #ff4f4f;
}

