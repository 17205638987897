.buttonsTd {
  width: 200px;
  position: relative;
}

.buttonsContainer2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button1 {
  flex-shrink: 0;
  opacity: 0;
  width: 121px;
  height: 30px;
  background: #000000;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  cursor: pointer;
}

.button1 > *:first-child {
  margin-left: 10px;
}

.button1 > *:last-child {
  margin-right: 13px;
}
.reportTable {}
.reportTable > tr:not(:first-child):not(:nth-child(2)):hover .button1 {
  opacity: 1;
}

.reportTable > tr:not(:first-child):not(:nth-child(2)):hover .button2 {
  opacity: 1;
}

.button2 {
  flex-shrink: 0;
  opacity: 0;
  width: 30px;
  height: 30px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /*transform: scale(1.1);*/
  z-index: 10000;
  cursor: pointer;
}

.photoPopup:hover > div:last-child {
  display: block;
}

.photoPopup > div:last-child {
  display: none;
  height: auto !important;
  width: auto !important;
  z-index: 1000 !important;
  opacity: 1;
  border-radius: 6px;
  padding: 0 !important;
}

.disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}

.tablePhotoIcon {
  margin-left: 10px;
}

.tooltipWrapper {
  position: absolute;
  z-index: 100;
}