.notOpened {
    cursor: pointer;
    height: 28px;
    line-height: 28px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-height: 28px;
    width: 100%;
}

.wrapper {
    min-width: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.wrapper input {
    width: 100%;
    height: 28px;
    background: #f2f2f2;
    border-radius: 6px;
    border: none;
    padding: 7px;
    font-family: Roboto, sans-serif;
}

.wrapper input:focus {
    outline: none;
}

.tooltip {
    position: absolute;
    top: -13px;
    color: #f44336;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
}
