.wrapper {
    position: absolute;
    width: 648px;
    height: 520px;
    background: white !important;
    z-index: 100;
    border-radius: 6px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper.loading {
    filter: brightness(0.5) !important;
}

.topLine {
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
}

.topLine > div:first-child {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.topLine > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.topLine > div:nth-child(2) > img {
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}

.topLine > div:nth-child(2) > img:hover {
    background: #9a9a9a;
}

.btnImgContainer {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
}

.btnImgContainer:hover {
    background: #f2f2f2;
}

.wrapper > img,
.wrapper > .img {
    flex-grow: 1;
    flex-shrink: 0;
    width: calc(100% - 48px);
    height: auto;
    max-height: 432px;
    margin-bottom: 22px;
    margin-left: 24px;
    margin-right: 24px;
    object-fit: contain;
}

.btnImgContainer {
    img {
        width: 20px;
        height: 20px;
        margin: 0 5px;
        cursor: pointer;
    }
    input[type='file'] {
        display: none;
    }
}