.dashboard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    line-height: 14px;
    margin: 32px 32px 0;
}

.block {
    background: #ffffff;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin: 0 8px;
    padding: 14px 20px;
    flex-grow: 1;
    cursor: pointer;
    transition: opacity 0.2s;
    min-height: 192px;
}
.block.selected.preprocessing {
    box-shadow: 0px 10px 20px rgba(125, 162, 255, 0.35);
}
.block.selected.in_work {
    box-shadow: 0px 10px 20px rgba(106, 206, 171, 0.35);
}
.block.selected.no_contact {
    box-shadow: 0px 10px 20px rgba(255, 207, 36, 0.35);
}
.block.selected.cancelled {
    box-shadow: 0px 10px 20px rgba(255, 79, 79, 0.35);
}

.block.noSelected {
    opacity: 0.5;
}
.block.selected {
    opacity: 1;
}

.block.chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 0;
    text-align: center;
}
.block.chart svg {
    height: auto;
    margin-bottom: 8px;
}

.pie {
    position: relative;
    max-width: 54px;
}
.count {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-top: -18px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
}
.title .blockTotal {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.row {
    margin: 0 -20px;
}
.row .loader {
    border-radius: 0;
}
.row .loader .persent {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.loader {
    position: relative;
    width: 100%;
    height: 4px;
    background: #eeeeee;
    border-radius: 2px;
}

.loader .persent {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 2px;
    transition: all 0.5s;
}

.preprocessing .loader .persent {
    background: #7da2ff;
}
.in_work .loader .persent {
    background: #67e1b5;
}
.no_contact .loader .persent {
    background: #ffcf24;
}
.cancelled .loader .persent {
    background: #ff4f4f;
}

.element {
    margin-bottom: 8px;
}
.label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.preprocessing {
}
.mb2 {
    margin-bottom: 10px;
}
.mb3 {
    margin-bottom: 14px;
}

.element.autotarification_attempt .persent {
    background: #cccccc;
}
.element.new .persent {
    background: #8793fc;
}
.element.timepoint_confirmed .persent {
    background: #49c6fb;
}
.element.partly_confirmed .persent {
    background: #62a5fc;
}
.element.partly_arrived .persent {
    background: #48dcec;
}
.element.partly_photo_attached .persent {
    background: #33e6d1;
}
.element.photo_attached .persent {
    background: #5de3a3;
}
.element.finished .persent {
    background: #95e76e;
}
.element.no_response .persent,
.element.driver_callback .persent {
    background: #ffcf24;
}
.element.declined .persent,
.element.cancelled .persent,
.element.removed .persent,
.element.failed .persent,
.element.cancelled_with_payment .persent {
    background: #ff4f4f;
}

.element.autotarification_attempt .loader {
    background: #eeeeee;
}
.element.new .loader {
    background: #e7e9fb;
}
.element.timepoint_confirmed .loader {
    background: #dcf1fa;
}
.element.partly_confirmed .loader {
    background: #dbe9fa;
}
.element.partly_arrived .loader {
    background: #d4f2f5;
}
.element.partly_photo_attached .loader {
    background: #c5fbf4;
}
.element.photo_attached .loader {
    background: #c7f9e1;
}
.element.finished .loader {
    background: #dcfbce;
}
.element.no_response .loader,
.element.driver_callback .loader {
    background: #fef0c0;
}
.element.declined .loader,
.element.cancelled .loader,
.element.removed .loader,
.element.failed .loader,
.element.cancelled_with_payment .loader {
    background: #ffe1e1;
}
