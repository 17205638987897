.popup {
    width: 500px;
    height: auto;
    position: relative;
    padding-top: 40px;
    padding-left: 40px;
    word-break: break-word;

    @media (max-width: 1000px) {
        width: 80vw;
        // padding-top: 10vh;
        padding: 5vw 5vw 0 5vw;
    }
}

.popupContent {
    margin-right: 40px;
}

.popupClose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.popupTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 40px;
}

.button {
    margin-bottom: 40px;
    margin-top: 40px;
    width: 149px;
    height: 48px;
    background: #000000;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1000px) {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

.button:first-child {
    margin-right: 15px;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 601px) {
    .popup {
        width: 100%;
        height: 100%;
        padding: 25px;
    }

    .popupContent {
        margin: 0 !important;
    }

    .button {
        margin-bottom: 15px;
    }
}
